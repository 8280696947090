body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.graph{
  width: 100%;
  height: 100%;
}
/* Navbar container */
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}

/* Navbar items */
ul li {
  float: left;
}

/* Navbar links */
ul li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
  font-size: 1rem;
}

/* Change link color on hover */
ul li a:hover {
  background-color: #575757;
}

/* Active link */
ul li a.active {
  background-color: #4CAF50;
}
.container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* Add this to your CSS file or in a <style> tag within the component */
.button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 20px 0;
}

.button-grid button {
  padding: 10px 10px;
  width: 10rem;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button-grid button:hover {
  background-color: #0056b3;
}

.home-container{
  display: flex
  ;
  align-items: center;
  justify-content: center;
}
/* Responsive adjustments */
@media screen and (max-width: 600px) {
  ul li {
      float: none;
      width: 100%;
  }
}
