

.org-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 50rem;

}
li{
    color:bisque
}